import {
  MD,
  ST,
  T,
  I,
  B,
} from 'CORE__UI/globals/SessionExpiry/core__session-expiryStyles';
import styled from 'styled-components';
import { GREYS } from 'UI/globals/colours';
const JN_I = styled(I)`
  color: ${GREYS.white};
`;

const JN_T = styled(T)`
  color: ${GREYS.white};
`;
const JN_B = styled(B)`
  color: ${GREYS.white};
`;
const OLY_MStyles = {
  modal: {
    padding: '24px',
    width: '480px',
    borderRadius: '4px',
    margin: 0,
    backgroundColor: GREYS.silverD,
  },
};

export { MD, ST, JN_T as T, JN_I as I, JN_B as B, OLY_MStyles as MStyles };
