import {
  WRAPPER_MAIN,
  LOGIN_BUTTON,
  REG_BUTTON,
  REG_BUTTON_W,
  REG_BUTTON_MESSAGE,
  INFO_BUTTON,
  WRAPPER,
  ROW,
  COL,
  D_WRAPPER,
  IMG,
  BALANCE_DIV,
  WRAPPER_DIV,
  PROGRESS_BAR,
  PROGRESS_BAR_CON,
  DASHBOARD_BUTTON,
  BUTTON_DIV,
  EXP_IMG,
  WRAPPER_DIV_TAG,
  DASHBOARD_BUTTON_TAG,
  ROW_TAG,
  IMG_TAG,
  COL_TAG,
  PROGRESS_BAR_CON_TAG,
} from 'CORE__UI/apps/AuthorizationHeader/core__authHeaderOly';
import styled, { css } from 'styled-components';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
import { GREYS, BRAND } from 'UI/globals/colours';

const REG_BUTTON_EX = styled(REG_BUTTON)`
  margin-right: 8px;
  border-radius: 24px;
  height: 40px;
  letter-spacing: 0.04em;
  &:hover {
    border: ${GREYS.white};
  }
  color: ${GREYS.white};
  @media (max-width: ${BREAKPOINT_S}) {
    color: ${GREYS.white};
    font-weight: 700;
    span span {
      width: 90%;
      min-width: 64px;
      text-align: center;
      margin-right: 8px;
    }
    @media (hover) {
      &:hover {
        background: ${BRAND.secondary};
      }
    }
    ${({ hide }) =>
      hide &&
      css`
        display: -webkit-inline-box;
        background: none;
      `}
  }
`;
const LOGIN_BUTTON_EX = styled(LOGIN_BUTTON)`
  border-radius: 24px;
  font-size: 14px;
  height: 40px;
  background-color: ${({ colorChange }) =>
    colorChange ? BRAND.secondary : BRAND.newAuthButton};
  letter-spacing: 0.04em;
  span {
    margin-left: 0;
  }
  @media (max-width: ${BREAKPOINT_S}) {
    width: auto;
    min-width: 64px;
    font-size: 12px;
  }
`;

const DASHBOARD_BUTTON_EX = styled(DASHBOARD_BUTTON)`
  width: 245px;
`;

const BALANCE_DIV_EX = styled(BALANCE_DIV)`
  @media (max-width: ${BREAKPOINT_S}) {
    margin-bottom: 8px;
  }
`;

const PROGRESS_BAR_EX = styled(PROGRESS_BAR)`
  width: 240px;
  margin: 0;
`;

const BUTTON_DIV_EX = styled(BUTTON_DIV)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const COL_EX = styled(COL)`
  ${({ ellipses }) =>
    ellipses &&
    css`
      @media (max-width: ${BREAKPOINT_S}) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 55px;
      }
    `}
`;

export const MStylesNew = {
  modal: {
    width: '280px',
    position: 'absolute',
    borderRadius: '4px',
    overflow: 'auto',
    margin: 0,
    color: GREYS.white,
    backgroundColor: GREYS.blueM,
    top: '70px',
    right: '24px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
  },
};

export {
  WRAPPER_MAIN,
  LOGIN_BUTTON_EX as LOGIN_BUTTON,
  REG_BUTTON_EX as REG_BUTTON,
  REG_BUTTON_W,
  REG_BUTTON_MESSAGE,
  INFO_BUTTON,
  WRAPPER,
  MStylesNew as MStyles,
  ROW,
  ROW_TAG,
  COL_EX as COL,
  COL_TAG,
  D_WRAPPER,
  IMG,
  BALANCE_DIV_EX as BALANCE_DIV,
  WRAPPER_DIV,
  WRAPPER_DIV_TAG,
  PROGRESS_BAR_EX as PROGRESS_BAR,
  PROGRESS_BAR_CON,
  PROGRESS_BAR_CON_TAG,
  DASHBOARD_BUTTON_EX as DASHBOARD_BUTTON,
  DASHBOARD_BUTTON_TAG,
  BUTTON_DIV_EX as BUTTON_DIV,
  EXP_IMG,
  IMG_TAG,
};
