import styled, { css } from 'styled-components';
import { GREYS, FONT, BRAND, SHADOW } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

export const WRAPPER = styled.div`
  font-weight: 800;
  display: flex;
  flex-direction: column;
  background-color: ${GREYS.blueM};
`;
export const WRAPPER_MAIN = styled.div`
  font-weight: 800;
  display: flex;
`;
export const LOGIN_BUTTON = styled.span`
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 10px;
  height: 32px;
  border-radius: 4px;
  color: white;
  margin-right: 8px;
  white-space: nowrap;
  &:hover {
    border: 2px solid ${GREYS.white};
  }
  @media (hover: none) {
    &:hover {
      border: none;
    }
  }
  @media (max-width: ${BREAKPOINT_S}) {
    padding: 0 8px;
    font-size: 12px;
  }
  margin-right: 8px;
  //margin for icon
  span {
    margin-left: 8px;
  }
`;

export const CT = styled.span`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 32px;
  color: white;
  white-space: nowrap;
  //margin for icon
  span {
    margin-right: 8px;
  }
`;

export const REG_BUTTON = styled.span`
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 32px;
  border-radius: 4px;
  background-color: ${FONT.secondary};
  color: ${GREYS.black};
  &:hover {
    background-color: ${BRAND.secondary};
  }

  @media (max-width: ${BREAKPOINT_S}) {
    width: auto;
    background-color: transparent;
    color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
    font-weight: normal;
    font-size: 12px;
    &:hover {
      background-color: ${BRAND.secondaryD};
    }

    ${({ hide }) =>
      hide &&
      css`
        display: none;
      `}
  }
`;

export const REG_BUTTON_W = styled.span`
  @media (max-width: ${BREAKPOINT_S}) {
    display: none;
  }
`;
export const REG_BUTTON_MESSAGE = styled.span`
  display: none;
  @media (max-width: ${BREAKPOINT_S}) {
    display: block;
    padding: 0;
  }
`;

export const EC = styled.div`
  display: flex;
  align-items: center;
`;

export const E = styled.span`
  background-color: ${BRAND.primaryL};
  padding: 12px;
  font-size: 14px;
  color: ${FONT.light};
  cursor: pointer;
`;

export const B = styled.div`
  background-color: ${BRAND.primaryL};
  padding: 12px;
  border-radius: 0 4px 4px 0;
  background-color: ${FONT.light};
  color: ${FONT.dark};
  font-weight: normal;
  text-align: right;
  width: auto;
  min-width: 100px;
  padding-left: 12px;
  @media (max-width: 1280px) {
    min-width: unset;
  }
`;

export const EX = styled.div`
  padding: 12px;
  width: 120px;
  color: ${BRAND.primary};
  border-radius: 4px;
  background-color: ${FONT.light};
  text-align: center;
  cursor: pointer;
`;

export const BS = styled.div`
  margin-right: 16px;
  display: flex;
`;

export const R = styled.div`
  cursor: pointer;
  color: ${FONT.light};
  font-size: 14px;
  position: relative;
`;
export const RS = styled.a`
  margin-right: 40px;
  @media (max-width: 1280px) {
    margin-right: 16px;
  }
  font-weight: normal;
`;

export const MB = styled.div`
  color: ${({ active }) => (active ? FONT.secondary : FONT.light)};
  margin-right: 40px;
`;

export const BC = styled.span`
  top: -4px;
  right: 24px;
  min-height: 12px;
  min-width: 12px;
  background-color: ${FONT.secondary};
  border-radius: 50%;
  font-size: 10px;
  color: ${FONT.dark};
  padding-left: 4px;
  position: absolute;
`;
export const TCTP = styled.button`
  background-color: inherit;
  color: ${GREYS.white};
  padding-inline: 36px;
  padding-block: 8px;
  border-radius: 4px;
  border: 1px solid ${GREYS.white};
`;

export const SI = styled.img`
  height: 12px;
  top: -5px;
  position: absolute;
  left: 13px;
`;

export const UAI = styled.span`
  position: relative;
`;

export const CP = styled.div`
  position: fixed;
  width: 344px;
  box-shadow: ${SHADOW};
  border-radius: 4px;
  top: 35%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: ${GREYS.silverD};
  border: 1px solid ${GREYS.silver};
  color: ${GREYS.white};
`;

export const TT = styled.div`
  text-align: center;
  font-size: 14px;
`;

export const L = styled.div`
  margin: 24px;
  padding: 8px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: ${GREYS.white};
  border: 1px solid ${GREYS.silver};
  border-radius: 4px;
  cursor: pointer;
`;

export const C = styled.div`
  text-align: right;
  font-size: 18px;
  cursor: pointer;
`;

export const DC = styled.div`
  justify-content: space-between;
  display: flex;
  padding: 24px;
`;

export const ROW = styled.div`
  display: flex;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  gap: 5px;
  align-items: center;
`;

export const ROW_TAG = styled(ROW)`
  @media (max-width: ${BREAKPOINT_S}) {
    p {
      font-size: 22px;
    }
  }
`;

export const COL = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
`;

export const COL_TAG = styled(COL)`
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 22px;
    line-height: 1.1;
    p:first-child {
      font-size: 12px;
    }
  }
`;

export const D_WRAPPER = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  @media (max-width: ${BREAKPOINT_S}) {
    display: none;
  }
`;
export const INFO_BUTTON = styled.div`
  span {
    margin-right: 2px;
    margin-left: 5px;
  }
`;

export const MStyles = {
  modal: {
    width: '392px',
    borderRadius: '4px',
    margin: 0,
    backgroundColor: GREYS.blueG,
  },
};

export const IMG = styled.img`
  height: 28px;
  width: 28px;
  @media (max-width: ${BREAKPOINT_S}) {
    height: 22px;
    width: 22px;
  }
`;

export const IMG_TAG = styled(IMG)`
  @media (max-width: ${BREAKPOINT_S}) {
    height: 32px;
    width: 32px;
  }
`;

export const EXP_IMG = styled.img`
  height: 14px;
  width: 14px;
  transform: ${props => (props.rotate ? 'rotate(180deg)' : 'unset')};
`;
export const BALANCE_DIV = styled.div`
  background: linear-gradient(96.68deg, #1a1f4a 0%, #222d79 100%);
  padding: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
  @media (min-width: ${BREAKPOINT_S}) {
    display: none;
  }
`;
export const WRAPPER_DIV = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 20px;
`;

export const WRAPPER_DIV_TAG = styled(WRAPPER_DIV)`
  @media (max-width: ${BREAKPOINT_S}) {
    padding: 12px 20px;
  }
`;

export const PROGRESS_BAR = styled.div`
  background-color: ${GREYS.greyB};
  width: 250px;
  border-radius: 24px;
  height: 10px;
  margin: 0px 10px;
  :after {
    content: '';
    display: block;
    background: linear-gradient(90deg, #e7c470 29.44%, #dda827 55.74%);
    width: ${props => props.value}%;
    height: inherit;
  }
`;
export const PROGRESS_BAR_CON = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  @media (max-width: ${BREAKPOINT_S}) {
    flex-direction: column;
  }
`;

export const PROGRESS_BAR_CON_TAG = styled(PROGRESS_BAR_CON)`
  @media (max-width: ${BREAKPOINT_S}) {
    margin: 4px 0;
    div::after {
      border-radius: 8px;
    }
  }
`;

export const BUTTON_DIV = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: auto;
  margin-bottom: 10px;
`;

export const DASHBOARD_BUTTON = styled.button`
  width: 166px;
  height: 36px;
  background-color: ${GREYS.blueM};
  color: ${GREYS.white};
  border: 1.6px solid ${GREYS.white};
  border-radius: 25px;
  text-transform: uppercase;
  cursor: pointer;
`;

export const DASHBOARD_BUTTON_TAG = styled(DASHBOARD_BUTTON)`
  @media (max-width: ${BREAKPOINT_S}) {
    width: 245px;
    font-size: 16px;
    height: 100%;
    padding: 8px;
  }
`;
