import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import getComposeEnhancers from 'Services/redux/core__redux-dev-tools';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { clientURL } from '../AccountApp/core__AccountInitialState';
import { getRequest } from 'Services/http/core__axios';

export const dataReducer = (state = [], action) => {
  switch (action.type) {
    //Storing customer data in redux store
    case 'CUSTOMER_DATA': {
      return {
        ...state,
        customerData: action.data,
      };
    }

    case 'LOGOUT_POPUP': {
      return {
        ...state,
        logoutPopup: action.payload,
      };
    }
    case 'SHOW_BALANCE': {
      return {
        ...state,
        show: action.payload,
      };
    }
    case 'CUSTOMER_BALANCE': {
      return {
        ...state,
        customerBalance: action.balance,
      };
    }
    default:
      return state;
  }
};

//Function to get the url for free bet
export function getCustomerUrl() {
  const base = `${clientURL.fsb}/customer`;
  const url = `${base}.fsb`;
  return url;
}
//function to fetch customer details
/**
 * @param {*} carouselSearchBar fucntion call in initial load
 *
 * */

//TODO - REVIEW

export const getUserVerification = token => dispatch => {
  const fetchUrl = `${clientURL.fsb}/kycDocument/idocufy?access_token=${token}`;
  const requester = getRequest;
  return requester(fetchUrl).then(res => {
    const data = res.customer;
    //publish event to LastLoggedIn App to show user last logged in details
    dispatch({ type: 'CUSTOMER_VERIFICATION', data });
  });
};

export const getUserDetails = token => dispatch => {
  const url = getCustomerUrl();
  const fetchUrl = `${url}?access_token=${token}&productGroupRef=SPORTSBOOK,CASINO&details=true&metadata=true&platformConfig=true${
    '&prevSessionCreated=true' || ''
  }`;

  if (!token) return;

  const requester = getRequest;
  return requester(fetchUrl).then(res => {
    const data = res.customer;
    //publish event to LastLoggedIn App to show user last logged in details

    PubSub.emit(PubsubEvents.LAST_LOGGED_IN, data);

    dispatch({ type: 'CUSTOMER_DATA', data });
  });
};

//Combine reducers into a single reducer
export const reducer = combineReducers({
  data: dataReducer,
});

//Storing the Customer details
export const loginData = data => dispatch => {
  if (data?.accessToken) {
    getUserDetails(data.accessToken);
    dispatch({ type: 'CUSTOMER_DATA', data });
  }
};

//function to show hide the balance
export const showBalance = show => dispatch => {
  let showValue = show;
  if (show) {
    showValue = false;
  } else {
    showValue = true;
  }
  dispatch({
    type: 'SHOW_BALANCE',
    payload: showValue,
  });
};

//function to update account balance
export const updateAccountBalance = balance => dispatch => {
  dispatch({ type: 'CUSTOMER_BALANCE', balance });
};

const composeEnhancers = getComposeEnhancers({
  name: 'authorization-header-store',
});

export default initialState =>
  createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
