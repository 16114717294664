import React from 'react';
import {
  COL,
  COL_TAG,
  ROW,
  ROW_TAG,
  BALANCE_DIV,
  IMG_TAG,
  WRAPPER_DIV_TAG,
  PROGRESS_BAR,
  PROGRESS_BAR_CON_TAG,
  DASHBOARD_BUTTON_TAG,
  BUTTON_DIV,
} from 'UI/apps/AuthorizationHeader/AuthHeader-Oly';
import PropTypes from 'prop-types';
import Constants from '../../../../../../project';
import { Translations } from '../../../core__authorization-header-app';
import { numberWithSpaces } from '../../../core__authorization-header-app-utils';

const CaptainUpModal = ({ tierLevelData, balance }) => {
  return (
    <COL>
      <BALANCE_DIV>
        <ROW>
          <IMG_TAG
            src={`${Constants.staticPath.basePath}react/olybet/icons/loyalty/coin.svg`}
            alt="coins"
          />
          <COL_TAG>
            <p>{Translations.get('text.balance')}</p>
            <p>{numberWithSpaces(balance)}</p>
          </COL_TAG>
        </ROW>
      </BALANCE_DIV>
      <WRAPPER_DIV_TAG>
        <p>{Translations.get('text.nexttier')}</p>
        <ROW_TAG>
          <p>{numberWithSpaces(tierLevelData?.currentLevelPoints)}</p>
          <p>/</p>
          <p>{numberWithSpaces(tierLevelData?.nextLevelPoints)}</p>
        </ROW_TAG>

        <PROGRESS_BAR_CON_TAG
          onClick={() => {
            window.location.href = '/loyalty/dashboard/';
          }}
        >
          <PROGRESS_BAR value={tierLevelData?.progress} />
        </PROGRESS_BAR_CON_TAG>
        <ROW justifyContent={'space-between'}>
          <p>{tierLevelData?.currentLevelName}</p>
          <p>{tierLevelData?.nextLevelName}</p>
        </ROW>
      </WRAPPER_DIV_TAG>
      <br></br>
      <BUTTON_DIV>
        <DASHBOARD_BUTTON_TAG
          onClick={() => {
            window.location.href = '/loyalty/dashboard/';
          }}
        >
          {Translations.get('text.dashboard')}
        </DASHBOARD_BUTTON_TAG>
      </BUTTON_DIV>
    </COL>
  );
};
CaptainUpModal.propTypes = {
  tierLevelData: PropTypes.object,
  balance: PropTypes.number,
};
export default CaptainUpModal;
