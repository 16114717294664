import React, { useEffect, useState, Fragment } from 'react';
import {
  WRAPPER_MAIN,
  LOGIN_BUTTON,
  INFO_BUTTON,
  D_WRAPPER,
  MStyles,
  COL,
  ROW,
  IMG,
  EXP_IMG,
} from 'UI/apps/AuthorizationHeader/AuthHeader-Oly';
import { Translations } from '../../../core__authorization-header-app';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import { Modal } from 'CORE__UI/globals/Modal/core__modal';
import CaptainUpModal from '../CaptainUpModal/core__captainUpModal';
import Constants from '../../../../../../project';
import { numberWithSpaces } from '../../../core__authorization-header-app-utils';

const CaptainUpButton = () => {
  const [userList, setUserListCU] = useState();
  const [userCoins, setUserCoins] = useState(0);
  const [tierLevelData, setTierLevelData] = useState({
    currentLevelPoints: '',
    currentLevelName: '',
    currentLevelImage: '',
    nextLevelPoints: '',
    nextLevelName: '',
    progress: '',
  });
  const [showModal, setShowModal] = useState(false);
  const customer = FSBCustomerSession?.customerOBJ;
  useEffect(() => {
    const captainDataPubSub = PubSub.listen(PubsubEvents.CaptainData, data => {
      setUserListCU(data.getUserList);
      const currentLevelId = data?.userLevel?.id;
      const currentLevel = data.getLevels.find(l => l.id === currentLevelId);
      const nextLevel = data.getLevels.find(
        l => l.number === currentLevel?.number + 1
      );
      setTierLevelData({
        currentLevelPoints: data.getUserList.currencies?.points,
        currentLevelName: currentLevel?.name?.split('-')[1]?.trim(),
        currentLevelImage: currentLevel?.preset_image,
        nextLevelPoints: nextLevel?.points,
        nextLevelName: nextLevel?.name?.split('-')[1]?.trim(),
        progress:
          (data.getUserList.currencies?.points / nextLevel?.points) * 100,
      });
      setUserCoins(data.getUserList.currencies?.coin);
    });

    return () => {
      captainDataPubSub.unsubscribe();
    };
  }, []);

  return (
    <WRAPPER_MAIN>
      <LOGIN_BUTTON
        colorChange={!customer}
        onClick={() => {
          setShowModal(true);
        }}
      >
        <INFO_BUTTON>
          <ROW>
            <IMG src={tierLevelData?.currentLevelImage} />
            <D_WRAPPER>
              <COL>
                <p>{Translations.get('text.loyaltycoins')}</p>
                <p>{numberWithSpaces(userList?.currencies?.coin)}</p>
              </COL>
            </D_WRAPPER>
            <EXP_IMG
              rotate={showModal ? 'true' : undefined}
              src={`${Constants.staticPath.basePath}react/olybet/dropdown/dropdown/dropdown.png`}
              alt="dropdownIcon"
            />
          </ROW>
        </INFO_BUTTON>
      </LOGIN_BUTTON>
      <Modal
        data-test="close-modal"
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        center
        styles={MStyles}
        cantEscape={false}
        closeIcon={<Fragment></Fragment>}
      >
        <CaptainUpModal
          tierLevelData={tierLevelData}
          balance={userCoins}
        ></CaptainUpModal>
      </Modal>
    </WRAPPER_MAIN>
  );
};
export default CaptainUpButton;
