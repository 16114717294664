export const translationStrings = [
  'text.responsible.gambling',
  'text.login',
  'text.my.account',
  'text.join.now',
  'text.join',
  'text.deposit',
  'text.back',
  'text.my.bets',
  'text.header.rules',
  'text.header.help',
  'text.header.insert-card',
  'text.header.logout-confirm',
  'text.header.logout-confirm-no',
  'text.header.logout-confirm-yes',
  'shoplocator.title',
  'shoplocator.subtitle',
  'virtuals.next',
  'login.successful',
  'login.additional.info',
  'login.ok',
  'text.register',
  'text.message',
  'contact.required',
  'contact.subject',
  'contact.select.subject',
  'contact.requiredmessage',
  'contact.send',
  'text.back',
  'text.message.sent',
  'text.name',
  'text.email',
  'text.upload.file',
  'text.subject',
  'text.select.subject',
  'text.contact.us',
  'text.help.centre',
  'text.betting.rules',
  'text.privacy.policy',
  'text.cookie.hyperlink',
  'responsible.gamble',
  'text.success.close',
  'text.peppopup.title',
  'text.peppopup.content',
  'terms.title',
  'terms.manual',
  'login.additional.info.limits',
  'text.click.here',
  'text.badgesneeded',
  'text.prequisation',
  'text.howtogetit',
  'text.nexttier',
  'text.dashboard',
  'text.balance',
  'text.loyaltycoins',
  'text.completed',
  'text.claim',
];
