import React, { useEffect, useState } from 'react';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { AuthHeader } from './components/AuthHeader/core__authHeader';
import PropTypes from 'prop-types';
import { getCookie, createCookie } from 'Services/cookie/core__cookies';
import { decode } from '@msgpack/msgpack';
import SessionExpiry from 'UI/globals/SessionExpiry';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import { removeCookie } from 'Services/cookie/core__cookies';
import TRACKING_CONSTANTS from 'Services/constants/core__tracking';
/**
 * this component contains the authorization header including the login and signup
 * @param {*} loginData function for setting the login user data
 *
 **/

export const AuthorizationHeaderReact = props => {
  const [update, setUpdate] = useState(false);
  const [buttonStatus, setButtonStatus] = useState('');
  const sessionToken = getCookie('AppSession');

  useEffect(() => {
    const updateChallenges = PubSub.listen(
      PubsubEvents.UPDATE_CHALLENGES,
      () => {
        setUpdate(!update);
      }
    );
    return () => {
      updateChallenges.unsubscribe();
    };
  }, [update]);

  const updateSession = wsData => {
    // Function for sending the updated session data of customer
    props.getUserDetails(wsData.accessToken, props.showLoggedInTimer, wsData);
    setButtonStatus('account');
  };

  //React lifecycle for listening the pubsub events when the header is loaded
  useEffect(() => {
    const firstVisit = getCookie('firstVisit');
    const currentPath = window.location.pathname;
    if (firstVisit === true || firstVisit === 'true') {
      if (currentPath === '/') {
        window.location.href = '/casino/';
      }
    }
    if (!firstVisit || firstVisit === 'false') {
      document.getElementById('topheader-left-logo-link').href = '/';
    }

    if (firstVisit === false || firstVisit === 'false') {
      if (currentPath === '/') {
        createCookie('firstVisit', true);
      }
    }
    let sessionUpdatedListener;
    const session = FSBCustomerSession.getSession();
    if (session) {
      updateSession(session);
    } else {
      if (!sessionToken) setButtonStatus('login');
      // Pubsub event listen for any update in the session
      sessionUpdatedListener = PubSub.listen(
        PubsubEvents.SESSION_UPDATED,
        wsData => {
          updateSession(wsData);
        }
      );
    }

    const sessionCreateListener = PubSub.listen(
      PubsubEvents.SESSION_CREATED,
      wsData => {
        props.loginData(wsData);
        setButtonStatus('account');
        PubSub.emit(PubsubEvents.LoadCaptain, wsData);
      }
    );

    // Pubsub event listen for any update in the session
    const sessionUpdate = PubSub.listen(
      PubsubEvents.SESSION_UPDATED,
      wsData => {
        // Function for sending the updated session data of customer
        props.getUserDetails(
          wsData.accessToken,
          props.showLoggedInTimer,
          wsData
        );
        setButtonStatus('account');
      }
    );

    // Pubsub event listen when the session is destroyed
    const sessionDestroy = PubSub.listen(PubsubEvents.SESSION_DESTROY, () => {
      setButtonStatus('login');
      // Function for deleting session data of customer
      PubSub.emit(PubsubEvents.THIRD_PARTY_TRACKING, {
        event: TRACKING_CONSTANTS.LOGOUT_SUCCESS,
        data: {},
      });
      props.loginData(null);
    });

    const sessionDestroyed = PubSub.listen(
      PubsubEvents.SESSION_DESTROYED,
      () => {
        // Function for deleting session data of customer
        props.loginData(null);
      }
    );

    // Pubsub event listen when the session is being created
    const sessionCreate = PubSub.listen(PubsubEvents.SESSION_CREATE, wsData => {
      // Function for sending the session data of customer
      props.loginData(wsData, props.showLoggedInTimer);
      props.getUserDetails(wsData.accessToken, props.showLoggedInTimer, wsData);
      setButtonStatus('account');
    });

    const updateCustomer = PubSub.listen(
      PubsubEvents.UPDATE_CUSTOMER,
      token => {
        if (token)
          props.getUserDetails(token, false, FSBCustomerSession.getSession());
      }
    );

    // Pubsub emit and listen to get balance update
    PubSub.emit('ws:sub:balance', sessionToken);

    const customerBalanceUpdate = PubSub.listen(
      PubsubEvents.CUSTOMER_BALANCE_UPDATE,
      wsData => {
        if (wsData?.encodedData) {
          const getArray = new Uint8Array(wsData.encodedData);
          const message = decode(getArray);
          if (
            message.gameRejectionCode &&
            message.gameRejectionCode === 'maxStakeForBonus'
          ) {
            PubSub.emit(PubsubEvents.THIRD_PARTY_TRACKING, {
              event: TRACKING_CONSTANTS.BONUS_BET_REJECTED,
              data: {
                errorCode: message.gameRejectionCode,
                product: 'casino',
                errorMessage: message.gameRejectionMessage,
              },
            });
          }
          if (
            message.insufficientBalance &&
            message.insufficientBalance === true
          ) {
            PubSub.emit(PubsubEvents.THIRD_PARTY_TRACKING, {
              event: TRACKING_CONSTANTS.BONUS_TOO_LOW,
              data: {},
            });
          }
        }
      }
    );

    const balance = PubSub.listen('ws:balance', wsData => {
      if (wsData?.encodedData) {
        const getArray = new Uint8Array(wsData.encodedData);
        const data = decode(getArray);
        if (data?.cashBalance) {
          props.updateAccountBalance(Number(data?.cashBalance));
        }
      }
    });

    const updateBalanceWithData = PubSub.listen(
      PubsubEvents.UpdateBalanceWithData,
      wsData => {
        props.updateAccountBalance(wsData.customer.balance);
      }
    );

    // Return to cleanup Listeners
    return () => {
      sessionUpdatedListener?.unsubscribe();
      sessionCreateListener.unsubscribe();
      customerBalanceUpdate.unsubscribe();
      sessionUpdate.unsubscribe();
      sessionDestroy.unsubscribe();
      sessionDestroyed.unsubscribe();
      sessionCreate.unsubscribe();
      updateCustomer.unsubscribe();
      balance.unsubscribe();
      updateBalanceWithData.unsubscribe();
    };
  }, [props, sessionToken]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //pubsub to catch login and update the cookie accordingly
    const sessionCreated = PubSub.listen(PubsubEvents.SESSION_CREATED, () => {
      const UserAuthData = FSBCustomerSession.getSession();
      if (UserAuthData && UserAuthData.accessToken) {
        createCookie('userLoggedIn', true);
      }
    });
    const update = PubSub.listen(PubsubEvents.SESSION_UPDATED, () => {
      const UserAuthData = FSBCustomerSession.getSession();
      if (UserAuthData && UserAuthData.accessToken) {
        createCookie('userLoggedIn', true);
      }
    });
    const AppSession = getCookie('AppSession');
    if (!AppSession) {
      removeCookie('AppSession');
    }

    return () => {
      sessionCreated.unsubscribe();
      update.unsubscribe();
    };
  }, []);

  //Function for opening the login popup
  const openLogin = () => {
    // Pubsub event emitted for opening the login popup
    PubSub.emit(PubsubEvents.openLoginModal);
  };
  //Function for opening the registration popup
  const openRegistration = () => {
    window.location.href = '/register/';
  };
  //Function for opening the customer my account popup
  const openMyAccount = () => {
    // Pubsub event emitted for opening the customer my account popup and sending the customer data
    PubSub.emit(PubsubEvents.openMyAccount, {
      starIconUrl: props.starIconIndicator,
    });
  };

  return (
    <React.Fragment>
      <AuthHeader
        appConfig={props.appConfig}
        buttonStatus={buttonStatus}
        openLogin={openLogin}
        openRegistration={openRegistration}
        openMyAccount={openMyAccount}
        showBalance={props.showBalance}
        starIconIndicator={props.starIconIndicator}
      />

      <SessionExpiry />
    </React.Fragment>
  );
};

AuthorizationHeaderReact.propTypes = {
  appConfig: PropTypes.object,
  loginData: PropTypes.func,
  openLogout: PropTypes.func,
  logoutPopup: PropTypes.bool,
  showBalance: PropTypes.func,
  showMyBet: PropTypes.func,
  orisisAuth: PropTypes.bool,
  showMyBetCount: PropTypes.func,
  getUserDetails: PropTypes.func,
  updateAccountBalance: PropTypes.func,
  theme: PropTypes.string,
  additionalLoginInfo: PropTypes.bool,
  showLoggedInTimer: PropTypes.bool,
  starIconIndicator: PropTypes.string,
};
