import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  WRAPPER_MAIN,
  LOGIN_BUTTON,
  REG_BUTTON,
  REG_BUTTON_W,
  REG_BUTTON_MESSAGE,
  INFO_BUTTON,
  COL,
  ROW,
  IMG,
  EXP_IMG,
} from 'UI/apps/AuthorizationHeader/AuthHeader-Oly';
import { CurrencyFormat } from 'Services/global/core__currency-format';
import I from 'UI/globals/Icons';
import { getIcon } from 'Services/icons/core__icons';
import { Translations } from '../../../core__authorization-header-app';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import accountRedirectUrls from 'Services/global/core__accountRedirectUrls';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import Constants from '../../../../../../project';
import { StartCaptainUpService } from 'Services/core__services';
import CaptainUpButton from '../CaptainUpButton/core__captainUpButton';
import { numberWithSpaces } from '../../../core__authorization-header-app-utils';
/**
 * this component contains the header which includes login and sign up for the customer
 * @param {*} openLogin function for opening the login popup
 * @param {*} openRegistration function for opening the registration popup
 * @param {*} customerData object containing the details of the logged in customer
 * @param {*} openMyAccount function for opening the cusomer my account popup
 * @param {*} showBalance function for showing and hiding balance
 * @param {*} betCount number containing the bet count
 */

export const AuthHeader = ({
  appConfig,
  openLogin,
  openRegistration,
  openMyAccount,
  theme,
  buttonStatus,
}) => {
  const { customerData, customerBalance } = useSelector(state => ({
    customerData: state.data.customerData,
    customerBalance: state.data.customerBalance,
  }));
  const [balance, setCustomerBalance] = useState(0);
  const [userName, setUserName] = useState('');
  const [rotate, setRotate] = useState(false);
  const accountStatus =
    customerData?.raw?.accountStatus || customerData?.accountStatus;
  const customerMetadata =
    customerData?.raw?.customerMetadata || customerData?.customerMetadata;
  const showVerifyPopup = appConfig?.kycflagcheck
    ? !customerData?.kycApproved
    : accountStatus === GLOBAL_CONSTANTS.SUSPENDED &&
      !customerData?.kycApproved;
  const TurnOffDepositButton = appConfig?.disabledepositbutton;
  const customer = FSBCustomerSession?.customerOBJ;
  StartCaptainUpService();

  useEffect(() => {
    const element = document.getElementById('kycprompt');
    const Enablethirdstep = appConfig?.enablethirdstep;
    const findMetadata =
      (customerMetadata?.length &&
        customerMetadata?.find(data => data?.type === 'occupation')) ||
      {};
    if (customerData && showVerifyPopup) {
      if (element) {
        element.style.display = 'flex';
        element?.classList?.add('kycpromptstyle');
        document.getElementById('header')?.classList?.add('loggedIn');
      }
    }
    if (appConfig?.requestURL === '/my-account/') {
      if (element) {
        element.style.display = 'none';
      }
      document.getElementById('header')?.classList?.remove('loggedIn');
    }
    if (
      accountStatus &&
      accountStatus !== GLOBAL_CONSTANTS.ACTIVE &&
      Enablethirdstep &&
      !findMetadata?.id
    ) {
      if (
        accountStatus === GLOBAL_CONSTANTS.SUSPENDED ||
        GLOBAL_CONSTANTS.PENDING_ACTIVATION
      ) {
        PubSub.emit(PubsubEvents.SHOW_REGISTRATION_THIRD_STEP, true);
        if (window.location.pathname !== '/register/') {
          window.location.href = '/register/';
        }
      }
    }
  }, [customerData, appConfig, showVerifyPopup]); // eslint-disable-line react-hooks/exhaustive-deps

  const withdrawableBalance = customerData?.withdrawableBalance;

  useEffect(() => {
    setCustomerBalance(
      appConfig?.showwithdrawablebalance
        ? withdrawableBalance?.toFixed(2)
        : customerBalance?.toFixed(2) ||
            customerData?.balance?.balance?.toFixed(2) ||
            customerData?.balance?.toFixed(2) ||
            FSBCustomerSession?.customerOBJ?.balance?.balance?.toFixed(2) ||
            FSBCustomerSession?.customerOBJ?.balance?.toFixed(2)
    );
    if (FSBCustomerSession?.customerOBJ)
      setUserName(
        FSBCustomerSession?.customerOBJ?.firstName +
          ' ' +
          FSBCustomerSession?.customerOBJ?.lastName
      );
  }, [customerData, customerBalance]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const rotateIcon = PubSub.listen(PubsubEvents.ICON_ROTATE, data => {
      setRotate(data.open);
    });

    return () => {
      rotateIcon.unsubscribe();
    };
  }, []);

  return (
    <WRAPPER_MAIN>
      <Fragment>
        {/* Logged in Customer balance */}
        {buttonStatus === 'account' && !TurnOffDepositButton && (
          // Logged in Customer balance
          <a href={accountRedirectUrls.deposit}>
            <REG_BUTTON hide={true}>
              {Translations.get('text.deposit')}
            </REG_BUTTON>
          </a>
        )}
        {buttonStatus === 'login' && (
          <REG_BUTTON theme={theme} onClick={openRegistration}>
            <REG_BUTTON_W>{Translations.get('text.register')}</REG_BUTTON_W>
            <REG_BUTTON_MESSAGE>
              {Translations.get('text.register')}
            </REG_BUTTON_MESSAGE>
          </REG_BUTTON>
        )}
        {buttonStatus === 'login' && (
          <LOGIN_BUTTON
            colorChange={!customerData}
            onClick={() => {
              !customerData ? openLogin() : openMyAccount(customerData);
            }}
          >
            {Translations.get('text.login')}
          </LOGIN_BUTTON>
        )}

        {buttonStatus === 'account' && userName && (
          <LOGIN_BUTTON
            colorChange={!customerData}
            onClick={() => {
              !customerData ? openLogin() : openMyAccount(customerData);
            }}
          >
            <INFO_BUTTON
              onClick={() => {
                setRotate(!rotate);
              }}
            >
              <ROW>
                <span>
                  {customer?.raw?.avatarUrl ? (
                    <IMG src={customer.raw.avatarUrl} />
                  ) : (
                    <I
                      iconName={getIcon(GLOBAL_CONSTANTS.USER_ICON)}
                      size={20}
                    />
                  )}
                </span>

                <COL ellipses={true}>
                  {userName}
                  <ROW>
                    {numberWithSpaces(balance)}
                    {CurrencyFormat(customerData?.currencyCode)}
                  </ROW>
                </COL>

                <EXP_IMG
                  rotate={rotate ? 'true' : undefined}
                  src={`${Constants.staticPath.basePath}react/olybet/dropdown/dropdown/dropdown.png`}
                  alt="dropdownIcon"
                />
              </ROW>
            </INFO_BUTTON>
          </LOGIN_BUTTON>
        )}

        {accountStatus === GLOBAL_CONSTANTS.ACTIVE &&
          !appConfig?.hideloyaltybutton && <CaptainUpButton />}
      </Fragment>
    </WRAPPER_MAIN>
  );
};

AuthHeader.propTypes = {
  responsiblegambling: PropTypes.string,
  appConfig: PropTypes.object,
  openLogin: PropTypes.func,
  openRegistration: PropTypes.func,
  openMyAccount: PropTypes.func,
  orisisAuth: PropTypes.bool,
  openLogout: PropTypes.func,
  showBalance: PropTypes.func,
  showJNAuth: PropTypes.bool,
  theme: PropTypes.string,
  starIconIndicator: PropTypes.string,
  buttonStatus: PropTypes.string,
};

export default AuthHeader;
